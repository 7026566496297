import { css } from 'styled-components'

const AnimationRotateUp = css`
  @keyframes rotationUp {
    0% {
        transform: rotate(30deg);
      }
    25% {
      transform: rotate(60deg);
      }
    50% {
      transform: rotate(90deg);
      }
    75% {
      transform: rotate(130deg);
      }
    100% {
      transform: rotate(180deg);
      }
    }
`
export default AnimationRotateUp