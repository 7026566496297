import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useSWRConfig } from 'swr'
// @ts-ignore
import TagManager from 'react-gtm-module'

import { media } from 'styled-bootstrap-grid'
import styled from 'styled-components'

import { useAppSelector } from '@app/store'
import { useUser } from '@app/lib/hooks/useUser'
import { setEmployerId, setPayRateIndicatorState } from '@app/store/slices/employer-slice'
import { Status } from '@app/store/slices/auth-slice'

import BetterSelectDropdown from '@app/ui/components/SelectDropdown/BetterSelectDropdown'
import pxToRem from '@app/ui/utils/pxToRem'
import router from 'next/router'
import { extractBranchAndLocationForAnalytics } from '@app/lib/utils'

const CenterSection = styled.div`
  align-self: flex-end;
  margin: ${pxToRem(5)} ${pxToRem(20)} ${pxToRem(30)} ${pxToRem(20)};
  ${media.desktop`
    margin: ${pxToRem(20)} ${pxToRem(36)} ${pxToRem(20)} auto;
  `}
`

export const BranchSelector: React.FC = () => {
  const { employers, currentEmployerId } = useAppSelector(state => state.employers)
  const { mobileNavMenuIsOpened } = useAppSelector(state => state.app)
  const { status } = useAppSelector(state => state.auth)
  const { session } = useUser()
  const dispatch = useDispatch()
  const { mutate } = useSWRConfig()

  const menuOpenedAlternateStyle = (mobileNavMenuIsOpened) ? {marginBottom: pxToRem(0), transition: 'margin-bottom 0.5'} : {}
  const locationData = employers?.map((employer: any) => ({ label: employer.name, value: employer.id }))

  const updateUserLocation = (val: any) => {
    dispatch(setEmployerId(val))
    dispatch(setPayRateIndicatorState('request-switch-employer'))

    const branchAndLocation = extractBranchAndLocationForAnalytics(employers, currentEmployerId)
    
    TagManager.dataLayer({
      dataLayer: {
        userBranch: branchAndLocation.branch,
        userLocation: branchAndLocation.location
      },
    })

    void router.replace('/')
  }

  useEffect(() => {
    const asyncPropagateSession = async () => {
      await mutate('/api/auth/user')
    }

    if (status === Status.SUCCEEDED) asyncPropagateSession()
  }, [mutate, status])

  return <>
    {session?.isLoggedIn && locationData?.length > 1 &&
      <CenterSection style={menuOpenedAlternateStyle}>
        <BetterSelectDropdown
          icon="store"
          onChange={updateUserLocation}
          data={locationData}
          initialValue={currentEmployerId}
        />
      </CenterSection>
    }
  </>
}
