import { useUser } from '@app/lib/hooks/useUser';
import { getActiveLocationObjectFromSession } from '@app/lib/utils';
import { useTheme } from '@app/ui/theme/Context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import styled from 'styled-components';
import { faIdCardClip } from '@fortawesome/pro-regular-svg-icons'
import { media } from 'styled-bootstrap-grid';

const MemberNumberButton = styled.button`
  font-size: ${(props: any) => props.theme.fontSizes.smallx};
  color: ${props => props.theme.colors.white};
  font-weight: 500;
  background-color: transparent;
  border-radius: 8px;
  width: fit-content;
  border: 0;
  cursor: pointer;
  ${media.desktop`
    color: ${(props: any) => props.theme.colors.primary};
    background-color: ${(props: any) => props.theme.colors.white};
    padding: 0px 12px;
`}
`

const MemberNumberSpan = styled.span`
  font-weight: 700;
`

const MemberNumber: FC = () => {
    const { session } = useUser()
    const { colors } = useTheme()

    const memberNumber = getActiveLocationObjectFromSession(session)?.member_number

    const copiedMessage = 'Copied to clipboard'

    const copyTextToClipboard = async (e: any) => {
        e.stopPropagation()
        if ('clipboard' in navigator) {
            await navigator.clipboard.writeText(memberNumber);
            alert(copiedMessage);
        } else {
            document.execCommand('copy', true, memberNumber);
            alert(copiedMessage);
        }
    }
    
    return (
        memberNumber ? 
            <MemberNumberButton onClick={copyTextToClipboard}>
              <FontAwesomeIcon icon={faIdCardClip} color={colors.info} />
              &nbsp;
              Your member number <MemberNumberSpan>{memberNumber}</MemberNumberSpan>
            </MemberNumberButton> : null
    )
}

export default MemberNumber