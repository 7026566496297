import React, { ReactNode, useRef, useState, useEffect } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import useOnclickOutside from 'react-cool-onclickoutside'

import color from '../color-css/color'
import TextInput from '../TextInput/index'
import AnimationRotateUp from '@ui/components/commons/AnimationRotateUp'
import AnimationRotateDown from '@ui/components/commons/AnimationRotateDown'
import pxToRem from '@app/ui/utils/pxToRem'
import { ScrollBar } from '../ScrollBarComponent'
import { media } from 'styled-bootstrap-grid'

export type Props = {
  contentDefault?: string
  label?: string
  data?: { label: string, value?: any } []
  name?: string
  isValidated?: boolean
  inputFromProps?: string
  inputOnAverageFromProps?: string
  getStateFromAddress?: string
  onSelectedItemProp?: (wasSelected: boolean) => void
  onAverageData?: (data: any) => void
  onAverageSelected?: (wasSelected: boolean) => void
  validationComponent?: ReactNode
  tooltipComponent?: ReactNode
  disable?: boolean
  icon?: any
  initialValue?: any
  onChange: (value: string) => void
}

interface StyledIcon {
  interact?: number
}

const StyledIconExt = styled(FontAwesomeIcon) <StyledIcon>`
  position: absolute;
  color: ${props => props.interact ? color.tooltip_icon : color.blue_darker};
  height: 0.875rem;
  animation: ${props => props.interact ? 'rotationUp' : 'rotationDown'};
  animation-duration: .3s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 10px;
  font-size: ${pxToRem(10)} !important; // Override fa styles
  ${AnimationRotateUp}
  ${AnimationRotateDown}
`

interface IDropdownContainer {
  visibility: number
}

const DropdownContainer = styled.div<IDropdownContainer>`
  display: ${props => props.visibility ? 'block' : 'none'};
  position: absolute;
  margin-top: 0.875rem;
  width: 100%;
  background: ${color.white} 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 3.125rem #012F571A;
  border-radius: 0.625rem;
  z-index: 99999;
  ${media.desktop`
    width: 110%;
  `}
  &:before {
    content: "";
    position: absolute;
    left: 50%;
    top: -1.25rem;
    width: 0;
    height: 0;
    border-top: 0.9375rem solid transparent;
    border-right: 0.9375rem solid #fff;
    border-bottom: 0.875rem solid transparent;
    transform: rotate(90deg);
`

const DropdownValue = styled(TextInput) <{
  isChanged?: boolean
  hasIcon?: boolean
}>`
  height: ${pxToRem(30)};
  font-size: ${pxToRem(12)};
  color: ${props => props.theme.colors.primary};
  background-color: #ECF3FA;
  ${props => props.hasIcon && 'padding-left: 2.5rem'};
  ${media.desktop`
    font-size: ${pxToRem(13)};
  `}
`

const DropdownSuggestWrapped = styled.div`
  max-height: ${pxToRem(200)};
  overflow: auto;
  ::-webkit-scrollbar {
    width: 0.8125rem;
  }
  ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #EFEFEF;
  border-radius: 0.625rem;
  }
  ::-webkit-scrollbar-thumb {
  background: #EFEFEF;
  border-radius: 0.625rem;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

interface DropdownSuggestContentWrapped {
  value?: (input: string) => string,
}

const DropdownSuggestContentWrappedExt = styled.div<DropdownSuggestContentWrapped>`
  font-family: museo-sans, sans-serif;
  margin: 2rem 0 0 0;
  padding: 0 1.75rem 26px 1.75rem;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5625rem;
  color: ${color.grey_darker};
  overflow: auto;
  @media screen and (max-width: 992px) {
    width: 100%;
  }
  ${ScrollBar}
`

export const Select = styled('select') <{
  isInputSuccessAsBackgroundColor?: number,
  isChanged?: boolean,
}>`
  width: 100%;
  height: ${pxToRem(30)};
  background-color: ${props => props.isInputSuccessAsBackgroundColor ? color.blue : color.grey};
  transition: background 0.5s ease-in-out;
  border: 1px solid ${color.white};
  outline: none;
  color: ${props => props.isInputSuccessAsBackgroundColor ? color.blue_17_percent : color.grey_dark};
  font-size: ${pxToRem(12)};
  padding-left: 0.9375rem;
  border-radius: 0.625rem;
  appearance: none;
  ${props => props.isChanged && `
    background-color: #ECF3FA;
    color: ${(props: any) => props.theme.colors.primary};
  `}
  option {
    background-color: ${(props: any) => props.theme.colors.white};
  }
  &:focus {
    ::input-placeholder { color:transparent; }
    border: 1px solid ${color.blue_darker};
  }
`

const DesktopVersion = styled.div`
  position: relative;
  cursor: pointer;
`

const DropdownSuggestContent = styled.span``

const DropdownSuggestContentKey = styled.div`
  margin-bottom: 0.8125rem;
  &:hover ${DropdownSuggestContent} {
    transition: color 0.5s ease-in-out;
    color: ${color.blue_17_percent}
  }
`

const InputHover = styled.div`
  &:hover ${StyledIconExt} {
    transition: color 0.5s ease-in-out;
    color: ${color.tooltip_icon}
  }
`

const SelectDropdownContainer = styled.div<{
  disable?: boolean
}>`
  ${props => props.disable && `
    pointer-events: none;
    opacity: 0.5;
  `}
`

const DynamicIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 0.4rem;
  left: 1.125rem;
  width: 1rem;
  color: #00AFD9;
`

export const BetterSelectDropdown: React.FC<Props> = (props) => {

  const {
    contentDefault = '',
    disable,
    data = [],
    name = '',
    validationComponent,
    tooltipComponent,
    icon,
    onChange,
    initialValue,
  } = props

  const [selectedIndex, setSelectedIndex] = useState(0)
  const [showingDropdownState, setShowingDropdown] = useState(false)
  const [isChanged, setIsChanged] = useState(false)
  const dropdownRef = useRef(null)

  const renderSuggestion = () =>
    data?.map((item: any, i: any) => {
      return (
        <DropdownSuggestContentKey
          key={i}
          tabIndex={0}
          onKeyDown={(e: any) => {
            if (e.key === 'Enter') {
              e.preventDefault()
            }
          }}
          onClick={(event: any) => {
            event.preventDefault()
            event.stopPropagation()
            onChange(item.value ?? item.label)
            setShowingDropdown(false)
            setIsChanged(true)
            setSelectedIndex(i)
          }}>
          <DropdownSuggestContent>
            {item.label}
          </DropdownSuggestContent>
        </DropdownSuggestContentKey>
      )
    })

  const innerRef = useOnclickOutside(() => {
    showingDropdownState && setShowingDropdown(false)
  }, {
    refs: [dropdownRef]
  })

  useEffect(() => {
    const newIndex = data.findIndex(el => el.value === initialValue)

    if (newIndex !== selectedIndex) setSelectedIndex(newIndex)
  }, [data, initialValue, selectedIndex])

  return (
    <SelectDropdownContainer disable={disable}>
      <DesktopVersion
        ref={dropdownRef}
        onClick={() => {
          setShowingDropdown(!showingDropdownState)
        }}>
        <InputHover>
          {icon && <DynamicIcon
            icon={{
              iconName: icon,
              prefix: 'far'
            }}
          />}
          <DropdownValue
            type="text"
            placeholder={contentDefault}
            name={name}
            onKeyDown={() => { }}
            value={data[selectedIndex]?.label ?? data[selectedIndex]?.value}
            showLabel={false}
            isChanged={isChanged}
            hasIcon={Boolean(icon)}
            inputMode="none"
            readOnly
          />
          <StyledIconExt interact={showingDropdownState ? 1 : 0} icon={faChevronDown} />
        </InputHover>
        <DropdownContainer
          ref={innerRef}
          visibility={showingDropdownState ? 1 : 0}>
          <DropdownSuggestWrapped>
            <DropdownSuggestContentWrappedExt>
              {renderSuggestion()}
            </DropdownSuggestContentWrappedExt>
          </DropdownSuggestWrapped>
        </DropdownContainer>
        {validationComponent}
        {tooltipComponent}
      </DesktopVersion>


      {
        // <ValidationIndicator isCorrect={inputSuccess} {...{ message: validatorMsg, visibility: validatorVisibilityState }} />
      }
    </SelectDropdownContainer>
  )
}

export default BetterSelectDropdown
