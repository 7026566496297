import { useCallback, useEffect, useState } from 'react'
import { debounce } from 'lodash'

export interface AppInterface {
  isDesktop: boolean
  isMobile: boolean
  screenWidth: number
}

export const useApp = (): AppInterface => {
  const [screenWidth, setScreenWidth] = useState<number>(0)
  const [isDesktop, setIsDesktop] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const debouncedCallback = debounce(setScreenWidth, 200)

  const handleWindowSizeChange = useCallback(() => {
    debouncedCallback(window.innerWidth)
  }, [debouncedCallback])

  useEffect(() => {
    setIsDesktop(screenWidth >= 992)
    setIsMobile(screenWidth < 992)
  }, [screenWidth])

  useEffect(() => {
    handleWindowSizeChange()
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    isDesktop,
    isMobile,
    screenWidth,
  }
}
