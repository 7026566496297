import styled, { css } from 'styled-components'

export const LabelStyle = css`
  font-family: museo-sans, sans-serif;
  font-weight: 500;
  line-height: 1.75rem;
  font-size: 1.125rem;
  text-align: left;
  color: #052948;
  @media screen and (max-width: 992px) {
    font-size: 1.0625rem;
    line-height: 1.5rem;
  }
`

const Label = styled.label`
  ${LabelStyle}
`

export default Label
