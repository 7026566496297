import React from 'react'
import { faSignOut } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const LogoutIcon = (props: any) => <FontAwesomeIcon
  icon={faSignOut}
  {...props}
/>

export default LogoutIcon
