
import React from 'react'
import { findIconDefinition, IconLookup } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import { ThemeConfig } from '@app/ui/theme/type'
import { useTheme } from '@app/ui/theme/Context'

export type FontIconProps = FontAwesomeIconProps & {
  icon: IconLookup,
  color?: keyof ThemeConfig['colors'],
}

export const FontIcon = ({ icon, color, ...rest }: FontIconProps) => {
  const { colors } = useTheme()
  const convertColor = color ? colors[color] : undefined
  const iconDef = findIconDefinition(icon)
  return iconDef ? (
    <FontAwesomeIcon
      icon={iconDef}
      color={convertColor}
      {...rest}
    />
  ) : null
}

export default FontIcon
