import React, { PropsWithChildren, useEffect } from 'react'
import styled from 'styled-components'

export type LabelIconProps = {
  iconStates?: boolean,
}

const handleButtonStates = (onState?: boolean) => {
  return onState && onState === true ? '#00AFD9' : '#D92241'
}

const LabelIconStyle = styled.span<{
  onState?: boolean
}>`
  color: ${({ onState }) => handleButtonStates(onState)};
`
const LabelIcon: React.FC<PropsWithChildren<LabelIconProps>> = ({ iconStates, children }) => {

  const [validation, setValidation] = React.useState(iconStates)

  useEffect(() => {
    setValidation(iconStates)
  }, [iconStates, validation])

  return (
    <LabelIconStyle onState={iconStates}>
      {children}
    </LabelIconStyle>
  )
}

export default LabelIcon
