import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components'

import color from '../color-css/color'
import Label from '../Label'
import LabelIcon from '../Label/LabelIcon'

export type InputMode = 'none' | 'text' | 'decimal' | 'numeric' | 'tel' | 'search' | 'email'

export type Props = {
  placeholder: string
  widthSize?: string
  name: string
  maxLength?: number
  type?: string
  inputFromProps?: string
  value?: string
  readOnly?: boolean
  paddingSize?: string
  clickedProp?: boolean
  isValidated?: boolean
  hideValidate?: any
  autoFocus?: boolean
  isInputSuccess?: boolean
  onBlur?: (event: any) => void
  onClick?: (event: any) => void
  onFocus?: (event: any) => void
  onInputValueCallback?: (inputValue: string) => void
  onTypeText?: (textInputValue: string) => void
  onInputSucessCallback?: (isSuccess: boolean) => void
  onVisibility?: (isVisible: boolean) => void
  onShow?: (isShow: boolean) => void
  onKeyDown?: (event: any) => void
  hasValueChange?: (isChange: boolean) => void
  validationComponent?: ReactNode
  tooltipComponent?: ReactNode
  showLabel?: boolean
  inputMode?: InputMode
}

interface IInputField {
  widthSize?: string,
  placeholder: string,
  inputmode: '',
  name: string,
  maxLength?: number,
  type?: string,
  isInputSuccess?: number,
  inputFromProps?: string,
  value?: string,
  readOnly?: boolean
  paddingSize?: string,
  onShow?: (isShow: boolean) => boolean,
  onTypeText?: (textInputValue: string) => void
}

export const InputStyled = css`
  font-family: museo-sans, sans-serif;
  width: 100%;
  @media screen and (max-width: 768px) {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding-left: 0.9375rem;
  }
  appearance: none;
  background-color: ${(props: any) => props.isInputSuccess ? color.blue : color.grey};
  transition: background 0.5s ease-in-out;
  border: 1px solid transparent;
  outline: none;
  color: ${(props: any) => props.isInputSuccess ? color.blue_17_percent : color.grey_dark};
  font-size: 1rem;
  padding: ${(props: any) => props.paddingSize ? props.paddingSize : '0.8125rem 1.625rem 0.75rem 1.5625rem'};
  border-radius: 0.625rem;
  ::placeholder,
  ::input-placeholder {
    color: ${color.grey_darker};
    font-family: museo-sans, sans-serif;
    font-size: 1rem;
  }
  &:focus {
    ::input-placeholder { color:transparent; }
    ::placeholder { color:transparent; }
    border: 1px solid ${color.blue_darker};
  }
  ::calendar-picker-indicator {
    opacity: 0;
  }
`

export const InputField = styled.input<IInputField>`
  ${InputStyled}
`

export const HoveringWrapper = styled.div`
  display: flex;
  &:hover ${InputField} {
    transition: border 0.5s ease-in-out;
    border: 1px solid ${color.blue_darker};
    border-radius: 0.625rem;
    cursor: pointer;
  }
`

const TextInput: React.FC<Props> = (props: any) => {
  const {
    onClick,
    onFocus,
    onBlur,
    onKeyDown,
    value,
    onChange,
    showLabel,
    validationComponent,
    tooltipComponent,
    ...rest
  } = props

  return (
    <>
      {
        showLabel ?
          (<Label>Label text
            <LabelIcon iconStates={props.isInputSuccess}>{'  '}*</LabelIcon>
          </Label>)
          :
          null
      }
      <HoveringWrapper>
        <InputField
          onFocus={event => onFocus && onFocus(event)}
          onBlur={event => onBlur && onBlur(event)}
          onClick={event => onClick && onClick(event)}
          value={value}
          onChange={event => onChange && onChange(event)}
          placeholder={props.placeholder}
          widthSize={props.widthSize}
          paddingSize={props.paddingSize}
          name={props.name}
          maxLength={props.maxLength}
          type={props.type}
          isInputSuccess={props.isInputSuccess ? 1 : 0}
          onKeyDown={() => onKeyDown && onKeyDown()}
          autoComplete='off'
          {...rest}
        />

      </HoveringWrapper>
      {validationComponent}
      {tooltipComponent}
    </>
  )
}

export default TextInput
