import { css } from 'styled-components'

const AnimationRotateDown = css`
  @keyframes rotationDown {
    0% {
      transform: rotate(180deg);
    }
    25% {
      transform: rotate(130deg);
    }
    50% {
      transform: rotate(90deg);
    }
    75% {
      transform: rotate(40deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
`
export default AnimationRotateDown