import React from 'react'
import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import pxToRem from '@app/ui/utils/pxToRem'
import { AnchorNavProps, HideOnDeviceProps } from '@app/ui/types/fcpprops'

export type HeadingProps = HideOnDeviceProps & AnchorNavProps & {
  heading?: string,
  centered?: boolean,
  headingType: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'h7' | 'eyebrow',
  backgroundColor?: string,
}

const H7 = styled.span`
  color: ${props => props.theme.heading.eyebrowColor};
  display: block;
  margin-bottom: ${pxToRem(23)};
  font-weight: 900;
  line-height: 1.5;
  ${media.xs`
    font-size: ${pxToRem(12)};
  `}
  ${media.sm`
    font-size: ${pxToRem(26)};;
  `}
`

const Eyebrow = styled(H7)`
  text-transform: uppercase;
  font-family: ${props => props.theme.fonts.intro};
  font-weight: 900;
`

const Container = styled.div`
  color: ${props => props.theme.heading.color};
`

const UnstyledHeading = ({
  headingType,
  heading,
  contentAnchorNavigation,
  ...props
}: HeadingProps) => {

  if (!['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'h7', 'eyebrow'].includes(headingType)) {
    return null
  }

  if (!heading) {
    return null
  }

  if (headingType === 'h7') {
    return <H7 {...props}>{heading}</H7>
  }

  if (headingType === 'eyebrow') {
    return <Eyebrow {...props}>{heading}</Eyebrow>
  }

  return <Container as={headingType} {...props}>
    {
      // eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/anchor-has-content
    }{contentAnchorNavigation?.enable && <a id={`${contentAnchorNavigation.linkTitle}`} />}
    {heading}
  </Container>
}

export const Heading = styled(UnstyledHeading)`
  text-align: ${props => (props.centered && 'center') || 'left'};
  ${props => props.backgroundColor && `background-color: ${props.backgroundColor};`};
`

export default Heading
