const color = {
  white: '#fff',
  green: '#299B1A',
  red: '#D92241',
  grey: '#F3F3F3',
  grey_dark: '#A9A9A9',
  grey_blended_blue: '#012F5733',
  grey_darker: '#757575',
  grey_shadow: '#012f571a',
  grey_29_percent: '#4A4A4A',
  grey_light: '#F3F3F3',
  blue: '#E2F1F8',
  blue_dark: '#052948',
  blue_darker: '#00AFD9',
  blue_17_percent: '#012F57',
  blue_15_percent: '#052948',
  tooltip_icon: '#FF6820',
  orange_darker: '#F2560B',
  grey_92_percent: '#E6EAEE',
  grey_95_percent: '#f0f2f5a1',
  grey_79_percent: '#c9c9c9'
}

export default color
