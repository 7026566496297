import React, { FC, useState } from 'react'
import styled, { css } from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import { useUser } from '@app/lib/hooks/useUser'
import { useLogout } from '@app/lib/hooks/useLogout'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MobileProfile from './MobileProfile'
import { useApp } from '@app/lib/hooks/app'
import router from 'next/router'
import pxToRem from '@app/ui/utils/pxToRem'
import useOnclickOutside from 'react-cool-onclickoutside'

import { LeftSection } from './LeftSection'
import { BranchSelector } from './BranchSelector'

import { getActiveBranchFromSession } from '@app/lib/utils'
import { faSignOut } from '@fortawesome/pro-regular-svg-icons'
import MemberNumber from '../MemberNumber'

const HeaderBar = styled.div`
  width: 100%;
  opacity: 1;
`

const Container = styled.div`
  background: ${props => props.theme.header.backgroundColor} 0% 0% no-repeat
  padding-box;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  margin-left: auto;
  margin-right: auto;
  ${media.desktop`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    align-content: space-between;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    position: relative;
    z-index: 11;
  `}
`

const DisplayOnDesktop = styled.div`
  display: none;
  ${media.desktop`
    display: flex;
  `}
`

const RightSide = styled.div`
  display: flex;
  cursor: pointer;
  padding-left: 0.9375rem;
  -webkit-tap-highlight-color: transparent;

  ${media.desktop`
    padding-left: 0rem;
    padding-top: 0rem;
    padding-bottom: 0rem;
    padding-right: 1rem;
  `}
`

const ProfileWrapper = styled.div`
  position: relative;
`

const NavLink = styled.div`
  color: #fff;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.5;
  padding-right: 2.625rem;
  ${media.desktop`
    margin: 1.2rem 0;
  `}
`

const WelcomeText = styled.div`
  font-family: 'Intro';
  color: #00afd9;
  text-transform: uppercase;
  font-size: 0.75rem;
  line-height: 2.33333;
  letter-spacing: 0px;
  font-weight: 900;
  display: block;
  ${media.desktop`
    display: none;
  `}
`

const Caret = styled.span`
  color: #ff6820;
  font-size: 1rem;
  line-height: 1.875;
  padding-left: 1.25rem;
`

const DropdownContent = styled.div<{$focus?: boolean}>`
  position: absolute;
  min-width: 270px;
  opacity: 0;

  pointer-events: none;
  z-index: 1102;
  transform: translateY(10px);
  transform: translateY(10px);
  -ms-transform: translateY(10px);
  -o-transform: translateY(10px);
  transform: translateY(10px);
  transition: all 0.7s ease-out;
  transition: all 0.7s ease-out;
  -ms-transition: all 0.7s ease-out;
  -o-transition: all 0.7s ease-out;
  transition: all 0.7s ease-out;

  ${props => props.$focus && css`
  opacity: 1;
  pointer-events: all;
  transform: translateY(0px);
  transform: translateY(0px);
  -ms-transform: translateY(0px);
  -o-transform: translateY(0px);
  transform: translateY(0px);
  `}

  ${media.desktop`
    right: 1rem;
  `}
  z-index: 999;
`

const DropdownMenu = styled.div`
  background: #fff 0% 0% no-repeat padding-box;
  box-shadow: 2px 6px 50px #012f571a;
  border-radius: 8px;
  padding: 1.5rem 0rem 1.5rem 2.3125rem;
  margin-top: 2rem;
  &:after {
    border-left: solid transparent 13px;
    border-right: solid transparent 13px;
    border-bottom: solid #fff 13px;
    top: 1.25rem;
    content: ' ';
    height: 0;
    left: 50%;
    position: absolute;
    width: 0;
  }
`

const DropdownItem = styled.a`
  display: block;
  width: 100%;
  clear: both;
  font-size: 1.125rem;
  line-height: 2.222222222;
  font-weight: 500;
  letter-spacing: -0.05px;
  color: ${props => props.theme.link.color};
  &:hover {
    color: #00afd9;
    text-decoration: underline;
  }
`

const LogoutItem = styled(DropdownItem)`
  font-size: ${props => props.theme.fontSizes.small2x};
  line-height: 2.857142857;
  letter-spacing: -0.04px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${pxToRem(5)};

  & > span {
    color: #00AFD9;
    text-decoration: underline;
    font-size: ${props => props.theme.fontSizes.small2x};
  }

  & > svg > path {
    fill: #00AFD9;
  }
`

const MenuText = styled.span`
  display: flex;
  align-items: center;
`

const JoinTheSDAButton = styled.a`
  display: inline-flex;
  align-items: center;
  padding-left: ${pxToRem(16)};
  padding-right: ${pxToRem(16)};
  height: ${pxToRem(36)};
  border-color: transparent;
  border-radius: 6.25rem;
  font-weight: 700;
  font-size: ${pxToRem(14)};
  color: #FFFFFF;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4,0,0.2,1);
  transition-duration: 150ms;
  cursor: pointer;
  margin-left: ${pxToRem(12)};
  justify-self: flex-end;
  background-color: ${props => props.theme.colors.warning};

  &:hover {
    background-color: #F2560B;
    color: white;
  }
`

const UpdateText = styled.a`
  margin: 0;
  font-size: ${pxToRem(18)};
`

export type MenuHeaderItem = {
  label: string
  url: string
}

export type MenuHeaderProps = {
  menuItems: Array<MenuHeaderItem>
}

export const MenuHeader: FC<MenuHeaderProps> = ({ menuItems }) => {
  const { session } = useUser()

  const { isMobile } = useApp()
  const performLogout = useLogout()

  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false)
  const [focus, setFocus] = useState(false)

  const notLoggedInAlternateStyles = (!session?.isLoggedIn) ? { justifyContent: 'flex-end', alignItems: 'center', paddingRight: '0.9375rem', paddingLeft: 0, paddingTop: 0, paddingBottom: '0.25rem' } : {}
  const notLoggedInContainerAlternateStyles = (!session?.isLoggedIn) ? {display: 'flex'} : {}

  const innerRef = useOnclickOutside(() => {
    setIsProfileModalOpen(false)
    setFocus(false)
  })

  const isDashboard = router.asPath === '/'
  const showWelcomeText = (isMobile && isDashboard)

  return (
    <HeaderBar>
      <Container style={notLoggedInContainerAlternateStyles}>
        <LeftSection />
        <div style={{ flex: 1 }}>
          <DisplayOnDesktop>
            <BranchSelector />
          </DisplayOnDesktop>
        </div>
        <RightSide
          onClick={() => setFocus(true)}
          style={notLoggedInAlternateStyles}
        >
          {!session?.isLoggedIn ? (
            <JoinTheSDAButton
              href='https://join.sda.com.au'
            >Join the SDA</JoinTheSDAButton>
          ) : (
            <ProfileWrapper ref={innerRef}>
              {showWelcomeText &&
                  <WelcomeText>Welcome Back,</WelcomeText>
              }
              {(!isMobile || isDashboard) &&
                <NavLink onClick={() => { setIsProfileModalOpen(!isProfileModalOpen) }}>
                  {session?.data && `${session.data?.given_name} ${session.data?.family_name}`}
                  {isMobile && <div><MemberNumber /></div>}
                  {!isMobile &&
                    <Caret>
                      <FontAwesomeIcon
                        icon={{
                          prefix: 'fas',
                          iconName: 'chevron-down',
                        }}
                        transform={{flipY: isProfileModalOpen}}
                      />
                    </Caret>
                  }
                </NavLink>
              }
              {(isProfileModalOpen && isMobile) &&
                <MobileProfile />
              }
              {(isProfileModalOpen && !isMobile) &&
                  <DropdownContent $focus={focus} >
                    <DropdownMenu>
                    <LogoutItem>
                        <MenuText onClick={() => {
                          performLogout().then(() => {
                            router.replace('/login')
                          })
                        }}>
                          <FontAwesomeIcon icon={faSignOut} style={{ marginRight: '0.5rem' }} />Log out
                        </MenuText>
                      </LogoutItem>
                        <UpdateText href={`/${getActiveBranchFromSession(session)?.toLowerCase()}/update-your-details`}>Update your details</UpdateText>
                      {menuItems?.map((menuItem, index) => {
                        return (
                          <DropdownItem key={index} href={menuItem.url}>
                            {menuItem.label}
                          </DropdownItem>
                        )
                      })}
                    </DropdownMenu>
                  </DropdownContent>
              }
            </ProfileWrapper>
          )}
        </RightSide>
      </Container>
    </HeaderBar>
  )
}

export default MenuHeader
