
import defaultLogo from '@app/assets/images/logo.png'
import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import { useAppSelector } from '@app/store'
import { BranchSelector } from './BranchSelector'

const LeftSide = styled.div`
  padding-left: 0.9375rem;
  ${media.desktop`
    padding-left: 2.375rem;
  `}
`

const LeftSideWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 0.75rem;
  padding-bottom: 0.25rem;
  height: 7%;
`

const NavBrand = styled.a``

const Logo = styled.img`
  height: 2.7rem;
  cursor: pointer;
`

const DisplayOnMobile = styled.span`
  display: flex;
  align-items: center;
  ${media.desktop`
    display: none;
  `}
`

export const LeftSection: React.FC = () => {
  const { siteSettings } = useAppSelector(state => state.app)

  return <LeftSideWrapper>
    <LeftSide>
      {siteSettings?.headerLogo?.sourceUrl && (<NavBrand href="/">
        <Logo src={siteSettings?.headerLogo.sourceUrl ?? defaultLogo.src} alt="Logo" />
      </NavBrand>)}
    </LeftSide>
    <DisplayOnMobile>
      <BranchSelector />
    </DisplayOnMobile>
  </LeftSideWrapper>
}
