import pxToRem from '@app/ui/utils/pxToRem'
import Link from 'next/link'
import { FC } from 'react'
import styled from 'styled-components'
import { LogoutIcon } from '@ui/components/icons/LogoutIcon'
import { ArrowRightIcon } from '@ui/components/icons/ArrowRightIcon'
import { useUser } from '@app/lib/hooks/useUser'
import { useLogout } from '@app/lib/hooks/useLogout'
import { useRouter } from 'next/router'
import { getActiveBranchFromSession } from '@app/lib/utils'

const Profile = styled.div`
  display: flex;
  flex-direction: column;
	animation-name: fade;
  animation-duration: 0.3s;
  animation-timing-function: linear;
  @keyframes fade {
    0% {
      opacity: 0;
    }
    75% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }

`

const UpdateDetailsWrapper = styled.a`
  background-color: #1A4469;
  color: white;
  padding: ${pxToRem(6.5)} ${pxToRem(10)};
	border-radius: 0.4rem;
	margin-top: ${pxToRem(30)};
	&:hover, &:focus, &:active {
		background-color: #03AFD9;
  	color: white;
		svg {
			color: white;
		}
  }
`

const SignoutIcon = styled(LogoutIcon)`
  margin-right: 0.475rem;
  color: white;
`

const ArrowRight = styled(ArrowRightIcon)`
  margin-left: 2.875rem;
  color: #06AAD5;
`

const LogoutButton = styled.button`
	background-color: #ff6820;
	border: none;
	border-radius: 2rem;
  color: white;
	margin-left: 0;
	width: ${pxToRem(110)};
	padding: ${pxToRem(6.5)} ${pxToRem(5)} ${pxToRem(6.5)} ${pxToRem(5)};
	margin-top: ${pxToRem(30)};
	&:hover, &:focus, &:active {
		background-color: #F1560B;
  	color: white;
  }
`

const MobileProfile: FC = () => {
  const performLogout = useLogout()
  const { session } = useUser()
  const router = useRouter()

  return (
    <Profile>
      <Link passHref legacyBehavior href={`/${getActiveBranchFromSession(session)?.toLowerCase()}/update-your-details`}>
        <UpdateDetailsWrapper>
          Update your details
          <ArrowRight />
        </UpdateDetailsWrapper>
      </Link>
      <LogoutButton onClick={() => {
        performLogout().then(() => {
          router.replace('/login')
        })
      }}>
        <SignoutIcon />
        Log out
      </LogoutButton>
    </Profile>
  )
}

export default MobileProfile
